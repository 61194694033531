<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="取消订单"
      @ok="handleSubmit"
    >
      <span>确定取消吗？</span>
      <a-form
        class="custom-compact-form"
        style="margin-top:50px;"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="取消原因">
          <a-textarea
            v-decorator="['remark', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入取消原因' },
                { max: 50, message: '最多50个字符' },
              ]
            }]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>

import { cancelOrder } from '@/api/order'
import { subscribeOrderCancel } from '@/api/subscribe_order'
import { additionalItemOrderCancel } from '@/api/additional_item_order'

export default {
  name: 'CancelServiceOrder',
  components: {
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    serviceOrderId: {
      type: Number,
      required: true
    },
    orderType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'cancel_service_order' }),
      submitting: false,
      data: {}
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          if (this.orderType === 'subscribe') {
            subscribeOrderCancel(this.serviceOrderId, values).then((res) => {
              if (res.code === 0) {
                // 关闭模态框
                this.isShow = false
                // 告知父组件已完成
                this.$emit('completed')
              }
              this.submitting = false
            })
          } else if (this.orderType === 'service') {
            cancelOrder(Object.assign({ order_id: this.id, order_type: this.orderType }, values)).then((res) => {
              if (res.code === 0) {
                // 关闭模态框
                this.isShow = false
                // 告知父组件已完成
                this.$emit('completed')
              }
              this.submitting = false
            })
          } else {
            additionalItemOrderCancel(this.serviceOrderId, values).then((res) => {
              if (res.code === 0) {
                // 关闭模态框
                this.isShow = false
                // 告知父组件已完成
                this.$emit('completed')
              }
              this.submitting = false
            })
          }
        }
      })
    }
  }
}
</script>
